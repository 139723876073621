import React from "react";
import MnfAccordion from "../components/mnf-accordion";
const Faqs = ({ faqs }) => {
  const display_faqs = faqs.map((faq) => ({
    title: faq.data.title.text,
    content: <div dangerouslySetInnerHTML={{ __html: faq.data.content.html }}></div>,
  }));

  return (
    <section className="block grid-container narrow" aria-labelledby="faqs">
      <h2 className="block-intro" id="faqs">
        FAQs
      </h2>

      <MnfAccordion items={display_faqs} />
    </section>
  );
};
export default Faqs;
