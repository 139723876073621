import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import Carousel from "../components/carousel";
const Quotes = ({ quotes }) => {
  /*   const data = useStaticQuery(graphql`
    query {
      cherylKnight: file(relativePath: { eq: "employees/cheryl-knight.jpeg" }) {
        childImageSharp {
          gatsbyImageData(width: 130)
        }
      }
      jadeBurnell: file(relativePath: { eq: "employees/jade-burnell.jpeg" }) {
        childImageSharp {
          gatsbyImageData(width: 130)
        }
      }
      jordanCooper: file(relativePath: { eq: "employees/jordan-cooper.jpeg" }) {
        childImageSharp {
          gatsbyImageData(width: 130)
        }
      }
    }
  `);

  const quotes = [
    {
      image: data.cherylKnight,
      author: "Cheryl Knight",
      role: "Collections Project Specialist",
      quote:
        "I’ve worked at MotoNovo since 2009 starting as a Collections Advisor, working my way up to Specialist Collections Advisor and more recently being promoted to Collections Project Specialist. The word culture is used a lot, but at MotoNovo, it has real meaning. There’s a genuine feeling that we’re part of a positive, inclusive team here in the Collections department and I’m sure I’m not the only one to have been so impressed by the support and flexibility that we were offered when working through the pandemic!",
    },
    {
      image: data.jadeBurnell,
      author: "Jade Burnell",
      role: "Recruitment Consultant",
      quote:
        "One of the core values of MotoNovo Finance is that we believe in our people as individuals.  When you meet us, we want to get to know you as a person; your experience, your qualifications, your transferable skills and what makes you unique. Someone that will enhance our culture, and take our business to the next level.  Don’t be afraid to tell us about your achievements, be yourself and let your talent shine – we are rooting for you!",
    },
    {
      image: data.jordanCooper,
      author: "Jordan Cooper",
      role: "Remote Account Team Manager",
      quote:
        "Since joining MotoNovo in 2016, I’ve seen first-hand how many opportunities there are for progression and personal development. I originally joined as a Personal Loans Advisor, before progressing into Team Leader and then into Manager. This year, I secured the Remote Account Team Manager position where I’ve been responsible for setting up a brand new team to ensure we're providing the best support for our dealers. I’ve met and worked with some brilliant people, been involved in some amazing charity activities including cycling from London to Paris and even set up a MotoNovo football team!",
    },
  ]; */

  return (
    <section className="grid-container medium block" aria-labelledby="#employeeQuotes">
      <h2 className="show-for-sr" id="employeeQuotes">
        Employee Quotes
      </h2>

      <Carousel
        show_pagination
        slides={quotes.map((quote, idx) => (
          <figure key={idx} className="grid-container narrow text-center quote">
            <figcaption>
              {quote.data.author}, {quote.data.role}
            </figcaption>
            <blockquote>{quote.data.quote.text}</blockquote>
          </figure>
        ))}
        pagination={quotes.map((quote, idx) => (
          <div className="quote-pagination-item" key={idx}>
            <GatsbyImage image={getImage(quote.data.image)} alt={quote.data.author} />
          </div>
        ))}
      />
    </section>
  );
};
export default Quotes;
