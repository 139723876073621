import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import PageHeader from "../components/page-header";
import Seo from "../components/seo";
import Faqs from "../content-components/faqs";
import Quotes from "../content-components/quotes";
import Layout from "../layouts/index";

const ApplicationProcess = ({ data }) => {
  if (!data) return null;

  const src = getImage(data.whatToExpectBanner);
  const faqs = data.allPrismicFaq.nodes;
  const testimonials = data.allPrismicTestimonial.nodes;

  return (
    <Layout>
      <Seo title="What to expect" />

      <PageHeader title="What to expect" />

      <section className="grid-container narrow block" aria-labelledby="applying">
        <h2 id="applying">Applying</h2>
        <p>
          The first step in the recruitment journey will be to complete an application and submit your CV. Application
          forms will generally ask questions based around the role in order to assess your experience, so this is an
          excellent opportunity to make yourself stand out. Don’t be shy in giving as much detail as you can about your
          skills and how they apply to the role.
        </p>
      </section>

      <section className="grid-container narrow block" aria-labelledby="interviews">
        <h2 id="interviews">Interviews</h2>
        <p>
          If your application is successful, the Recruitment Team will set up an interview with the hiring manager for
          the position that you're applying for. After having a chat about MotoNovo Finance, we'll discuss the role
          you've applied for and how it fits into our organisation. We'll ask you questions relating to the position
          you're applying for, your experience and skills. You'll also be able to ask us any questions you may have – we
          want to make sure that MotoNovo Finance is the right fit for you too! Interviews are a two-way process and
          it’s just as important for you to find out about us as it is for us to get to know you.
        </p>
      </section>

      <Quotes quotes={testimonials} />
      <GatsbyImage image={src} alt="Motonovo Finance - Interviews" />
      <section className="bg-mono-400" aria-labelledby="whatHappensAfter">
        <div className="grid-container narrow block pad">
          <h2 id="whatHappensAfter">What happens after you meet us?</h2>

          <p>
            Depending on the role you've applied for, we may invite you for an additional interview or to an assessment
            day. Don’t worry - we'll make sure you are fully aware of each stage of the recruitment journey.
          </p>

          <p>
            If you've been successful, a member of our recruitment team will call to make you an offer. This offer will
            include details of the salary and benefits that you can expect, and what the next steps will be. We want you
            to make the right decision when choosing MotoNovo Finance as the place to grow your career, so you don't
            have to accept straight away, but we'll certainly be looking forward to welcoming you to the team.
          </p>

          <p>
            In the event that you are unsuccessful, we will make sure to inform you as soon as possible, and offer
            feedback on your interview. We may also take the opportunity to discuss other vacancies we have available
            that we feel you would be interested in.
          </p>
        </div>
      </section>
      <Faqs faqs={faqs} />
    </Layout>
  );
};

export const query = graphql`
  query AppProcessQuery {
    allPrismicFaq(sort: { fields: data___order, order: DESC }) {
      nodes {
        data {
          title {
            text
          }
          content {
            html
          }
        }
      }
    }
    allPrismicTestimonial {
      nodes {
        data {
          author
          role
          quote {
            text
          }
          image {
            gatsbyImageData(width: 130, height: 130)
          }
        }
      }
    }
    whatToExpectBanner: file(relativePath: { eq: "what-to-expect-page-banner.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

export default ApplicationProcess;
